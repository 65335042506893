var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "card-title-actions"
  }, [_c('v-spacer'), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light"
    },
    on: {
      "click": function click($event) {
        return _vm.$previous();
      }
    }
  }, [_vm._v("Cancel")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "width": "100",
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save);
      }
    }
  }, [_vm._v(" Save ")]), _c('vx-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "light",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.save, true);
      }
    }
  }, [_vm._v(" Save & close ")])], 1), _c('v-card-text', {
    staticClass: "pt-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "name",
      "label": "Name",
      "required": ""
    },
    on: {
      "change": function change($event) {
        return _vm.testCase.setSlug(_vm.testCase.slug || _vm.testCase.name);
      }
    },
    model: {
      value: _vm.testCase.name,
      callback: function callback($$v) {
        _vm.$set(_vm.testCase, "name", $$v);
      },
      expression: "testCase.name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "name": "slug",
      "label": "Slug",
      "required": "",
      "disabled": _vm.$route.params.id && !_vm.dev
    },
    on: {
      "change": function change($event) {
        return _vm.testCase.setSlug(_vm.testCase.slug);
      }
    },
    model: {
      value: _vm.testCase.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.testCase, "slug", $$v);
      },
      expression: "testCase.slug"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "items": _vm.schemas,
      "item-text": "name",
      "item-value": "_id",
      "name": "Schema",
      "label": "Schema",
      "required": ""
    },
    on: {
      "change": _vm.getSchemaPages
    },
    model: {
      value: _vm.testCase.schemaId,
      callback: function callback($$v) {
        _vm.$set(_vm.testCase, "schemaId", $$v);
      },
      expression: "testCase.schemaId"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "items": _vm.pages,
      "item-text": "name",
      "item-value": "_id",
      "name": "Main Page",
      "required": "",
      "label": "Main Page"
    },
    on: {
      "change": _vm.onSelectMainPage
    },
    model: {
      value: _vm.testCase.pageId,
      callback: function callback($$v) {
        _vm.$set(_vm.testCase, "pageId", $$v);
      },
      expression: "testCase.pageId"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "autocomplete",
      "items": _vm.pages,
      "item-text": "name",
      "multiple": "",
      "item-value": "_id",
      "name": "Variants",
      "required": "",
      "label": "Variants"
    },
    model: {
      value: _vm.testCase.variants,
      callback: function callback($$v) {
        _vm.$set(_vm.testCase, "variants", $$v);
      },
      expression: "testCase.variants"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "disabled": "",
      "type": "text",
      "name": "path",
      "label": "Path",
      "required": ""
    },
    model: {
      value: _vm.testCase.path,
      callback: function callback($$v) {
        _vm.$set(_vm.testCase, "path", $$v);
      },
      expression: "testCase.path"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }