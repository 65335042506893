<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, true)">
          Save & close
        </vx-btn>
      </v-card-title>

      <v-card-text class="pt-8">
        <v-row>
          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="testCase.name"
              type="text"
              name="name"
              label="Name"
              required
              @change="testCase.setSlug(testCase.slug || testCase.name)"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="testCase.slug"
              type="text"
              name="slug"
              label="Slug"
              required
              :disabled="$route.params.id && !dev"
              @change="testCase.setSlug(testCase.slug)"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="testCase.schemaId"
              type="autocomplete"
              :items="schemas"
              item-text="name"
              item-value="_id"
              name="Schema"
              label="Schema"
              required
              @change="getSchemaPages"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="testCase.pageId"
              type="autocomplete"
              :items="pages"
              item-text="name"
              item-value="_id"
              name="Main Page"
              required
              label="Main Page"
              @change="onSelectMainPage"
            />
          </v-col>
          <v-col cols="12" sm="6" class="py-0">
            <vx-input
              v-model="testCase.variants"
              type="autocomplete"
              :items="pages"
              item-text="name"
              multiple
              item-value="_id"
              name="Variants"
              required
              label="Variants"
            />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="testCase.path" disabled type="text" name="path" label="Path" required />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import _ from 'lodash';
import { ABTestCase } from '@tutti/models';
import { ABTestCaseService, PageSchemaService, PageService } from '@tutti/services';

export default {
  data() {
    return {
      testCase: new ABTestCase(),
      schemas: [],
      pages: [],
      loading: false,
    };
  },
  computed: {
    // variants() {
    //   return this.pages ? _.filter(pages, (page) => page._id?.toString() );
    // },
  },
  created() {
    this.getSchemas();
    this.getTestCase();
  },
  methods: {
    async getTestCase() {
      if (this.$route.params.id) {
        const response = await ABTestCaseService.getByIdAdmin(this.$route.params.id);
        if (response) {
          this.testCase = new ABTestCase(response.data);
          this.getSchemaPages(this.testCase.schemaId);
        }
      }
    },
    async getSchemas() {
      const response = await PageSchemaService.getAll({ option: { wantPagination: false } });
      if (response && response.data.data.length) {
        this.schemas = _.sortBy(response.data.data, ['obj', 'name']);
      }
    },
    async getSchemaPages(schemaId) {
      const response = await PageService.getAll({ filter: { schemaId } });
      if (response && response.data.data.length) {
        this.pages = _.sortBy(response.data.data, ['obj', 'name']);
      }
    },
    onSelectMainPage(pageId) {
      const selectedPage = _.find(this.pages, page => page?._id?.toString() === pageId?.toString());
      this.testCase.path = selectedPage.path;
    },
    async save(previous) {
      this.loading = true;

      const response = await ABTestCaseService.save(this.testCase.get());
      if (response) {
        if (previous) {
          this.$previous();
        } else if (response.data._id) {
          this.$replace(this.$toEdit('ab-testcase', response.data._id));
        }
      }

      this.loading = false;
    },
  },
};
</script>
